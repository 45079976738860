import React, { useState } from 'react';
import './Projects.css';
import projectsData from './ProjectData';
import Modal from './Modal';

function ProjectCard({ image, title, description, onClick }) {
  return (
    <div className="project-card" onClick={onClick}>
      <img src={image} alt={title} className="project-image" />
      <div className="project-info">
        <p className="project-title">{title}</p>
        <p className="project-description">{description}</p>
      </div>
    </div>
  );
}

function Projects() {
  const [modalContent, setModalContent] = useState(null);

  const handleCardClick = (project) => {
    setModalContent(project.modalContent);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setModalContent(null);
    document.body.style.overflow = 'auto';
  };

  const categories = ['Hardware', 'Software / ML', 'Hardware + Software'];

  return (
    <div className="projects-titles">
    <h1 className="page-title">Some Things I've Worked On</h1>
    {categories.map((category) => (
        <React.Fragment key={category}>
          <h3 className="section-title">{category.charAt(0).toUpperCase() + category.slice(1)}</h3>
          <div className="projects-container">
            {projectsData.filter(project => project.category === category).map(project => (
              <ProjectCard
                key={project.id}
                image={project.image}
                title={project.title}
                description={project.description}
                onClick={() => handleCardClick(project)}
              />
            ))}
          </div>
        </React.Fragment>
      ))}
      {modalContent && <Modal content={modalContent} onClose={closeModal} />}
    </div>
  );
}

export default Projects;
