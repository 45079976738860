import React from 'react';
import './Intro.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faResearchgate } from '@fortawesome/free-brands-svg-icons';

function Intro() {
    return (
      <div className="intro">
        <div className="intro-image-wrapper">
          <img src="/paarth_image.jpeg" alt="Your Name" className="intro-image" />
          <div className="social-icons">
            <a href="https://www.linkedin.com/in/paarth-sharma/" aria-label="LinkedIn" className="social-icon">
                <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="https://scholar.google.com/citations?user=27sb0vUAAAAJ&hl=en" aria-label="Google Scholar" className="social-icon">
                <FontAwesomeIcon icon= {faResearchgate} />
            </a>
          </div>
        </div>
        <div className="intro-text">
          <h1 className="intro-name">Paarth Sharma 👋</h1>
          <p className="intro-description">Hi, I'm a BME from JHU. I like building fun, ambitious things which can have an impact. Sometimes this is software, other times hardware...nowadays usually a mix of both 🧠🛠🤖</p>
        </div>
      </div>
    );
  }

export default Intro;
