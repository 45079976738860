import React from 'react';
import './Modal.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { Carousel } from 'react-responsive-carousel';

function Modal({ content, onClose }) {
  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h2>{content.detailedTitle}</h2>
          <div className="modal-tags">
            {content.tags.map((tag, index) => (
              <span key={index} className="modal-tag">{tag}</span>
            ))}
          </div>
        </div>
        <div className="modal-body">
          <div className="modal-column-details">
          <h3 className="modal-section">Context</h3>
            <div className="modal-text" dangerouslySetInnerHTML={{ __html: content.context }}></div>
            <h3 className="modal-section">Technical Details</h3>
            <ul className="modal-text">
                {content.technicalDetails.map((detail, index) => (
                <li key={index} dangerouslySetInnerHTML={{ __html: detail }}></li>
            ))}
            </ul>
            <h3 className="modal-section">What Was Cool About This</h3>
            <ul className="modal-text">
              {content.coolStuff.map((item, index) => (
                <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
              ))}
            </ul>
            <h3 className="modal-section">Links</h3>
            <div className="modal-links">
              {content.links.map((link, index) => (
                <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">{link.text}</a>
              ))}
            </div>
          </div>
          <div className="modal-column-carousel">
            <Carousel
                showArrows={true}
                autoPlay={false}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
            >
                {content.images.map((item, index) => (
                    <div key={index}>
                        <img src={item.src} alt={`Slide ${index + 1}`} />
                        {item.caption && <p className="carousel-caption">{item.caption}</p>}
                    </div>
                ))}
            </Carousel>
        </div>
        </div>
        <button className="modal-close-button" onClick={onClose}><FontAwesomeIcon icon= {faX} /></button>
      </div>
    </div>
  );
}

export default Modal;