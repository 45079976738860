import aquatas_main from './img/aquatas_main.png';
import benegraft_main from './img/benegraft_main.png';
import deblur_main from './img/deblur_main.png';
import glioma_main from './img/glioma_main.png';
import heart_main from './img/heart_main.png';
import probe_main from './img/probe_main.png';
import sinogram_main from './img/sinogram_main.png';
import stethoscope_main from './img/stethoscope_main.png';
import tractor_main from './img/tractor_main.jpeg';
import hand_main from './img/hand_main.png';
import swarm_main from './img/swarm_main.png';
import lynq_main from './img/lynq_main.png';
import analog_main from './img/analog_main.png';

import aquatas_1 from './img/aquatas_1.png';
import aquatas_2 from './img/aquatas_2.png';
import aquatas_3 from './img/aquatas_3.png';
import aquatas_4 from './img/aquatas_4.png';
import aquatas_5 from './img/aquatas_5.png';

import analog_2 from './img/analog_2.png';
import analog_3 from './img/analog_3.png';
import analog_4 from './img/analog_4.png';

import benegraft_1 from './img/benegraft_1.png';
import benegraft_2 from './img/benegraft_2.png';
import benegraft_3 from './img/benegraft_3.png';

import ct_1 from './img/ct_1.png';
import ct_2 from './img/ct_2.png';
import ct_3 from './img/ct_3.png';
import ct_4 from './img/ct_4.png';

import deblur_1 from './img/deblur_1.png';
import deblur_2 from './img/deblur_2.png';
import deblur_3 from './img/deblur_3.png';

import glioma_1 from './img/glioma_1.png';
import glioma_2 from './img/glioma_2.png';
import glioma_3 from './img/glioma_3.png';

import hand_1 from './img/hand_1.png';
import hand_2 from './img/hand_2.png';
import hand_3 from './img/hand_3.png';
import hand_4 from './img/hand_4.png';

import heart_1 from './img/heart_1.png';
import heart_2 from './img/heart_2.png';
import heart_3 from './img/heart_3.png';
import heart_4 from './img/heart_4.png';
import heart_5 from './img/heart_5.png';
import heart_6 from './img/heart_6.png';
import heart_7 from './img/heart_7.png';

import lynq_1 from './img/lynq_1.png';
import lynq_2 from './img/lynq_2.png';
import lynq_3 from './img/lynq_3.png';

import probe_1 from './img/probe_1.png';
import probe_2 from './img/probe_2.png';
import probe_3 from './img/probe_3.png';
import probe_4 from './img/probe_4.png';

import stethoscope_1 from './img/stethoscope_1.png';
import stethoscope_2 from './img/stethoscope_2.png';
import stethoscope_3 from './img/stethoscope_3.png';
import stethoscope_4 from './img/stethoscope_4.png';
import stethoscope_5 from './img/stethoscope_5.png';

import tractor_1 from './img/tractor_1.png';
import tractor_2 from './img/tractor_2.png';
import tractor_3 from './img/tractor_3.png';
import tractor_4 from './img/tractor_4.png';
import tractor_5 from './img/tractor_5.png';
import tractor_6 from './img/tractor_6.png';
import tractor_7 from './img/tractor_7.png';

import temp from './img/temp.jpeg';


const projectsData = [
    {
        id: 1,
        category: 'Hardware',
        image: aquatas_main,
        title: 'Closed-Loop Mechnical RO',
        description: 'Aka hand-powered salt guzzler 🥤',
        modalContent: {
          detailedTitle: 'Closed-Loop Mechnical RO System 🥤',
          tags: ['Rapid Prototyping', 'Machining', 'LabView', 'C++', 'V&V', 'SOLIDWORKS'],
          context: 'You know those projects that you look back on and realize they helped define much of your professional trajectory? This was one of those for me - the classic “dorm room startup”, except instead of building with my laptop, I was sneaking stainless steel piping past McCoy Hall security. <br /><br /> The goal was to build a water purification system for heavy metal filtration in low-resource settings (i.e., no electricity, infrequent maintenance, low cost). This would directly tackle the increasing prevalence of heavy metal poisoning from water contamination due to climate change and mining practices.',
          technicalDetails: [
            'The inspiration came from traditional RO (reverse osmosis) systems which use large amounts of electrical power (3 - 10 kWh / m^3) to purify water by size separation (larger than 5 μm) - this is also prone to membrane fouling and excessive water loss of up to 90%',
            'Our design simplified this to a 14”x6”x18” lever-operated device, with low energy (able to be operated by a 5 year old child) and high permeate throughput (~0.5 L/min). A few key principles guided this:' +
            '<ul>' + 
              '<li><strong>Closed-loop system</strong> - whereas traditional RO operates view a high pressure water input stream, the concentrate is routed back to allow the entire system to pressurize over time (8-9 bar at steady state)...constant mixing also prevents salt gradients from forming</li>' +
              '<li><strong>Quad piston setup</strong> - in addition to the mechanical advantage with the lever (simple machine), a quad piston setup minimized needed vertical displacement while maintaining throughput</li>' +
              '<li><strong>Brine flush mechanism</strong> - using extensive testing to evaluate membrane caking, I added an automatic flush mechanism to dispense the concentrate with sufficient turbulence at the optimal point, thereby reducing the chance of membrane fouling</li>' +
              '</ul>',
            'The testing rig was set up for automated data acquisition with a National Instruments DAQ and later optimized (battery operated, low voltage for long-term data capture and storage on device) for a pilot in Sonora, Mexico',
            'All in all, the device was able to take in heavy metals (Lead, Arsenic, Mercury, Fluoride, etc.) at 10x the most concentrated water sources in literature and filter to below WHO guidelines for safe drinking water',
          ],
          coolStuff: [
            '<strong>Truly started from scratch</strong> - I started this alone in my dorm room purely out of a passion for the problem, with no knowledge of membrane processes. But by the end, I had a fully funded team, a novel solution, our own lab in the JHU ChemBE department, and production-ready devices for international IRB pilots',
            '<strong>It was practical</strong> - the final device could be produced at scale for ~$60, with ~$5 annual maintenance fee. Comparing this to the annual cost of the next, cheapest solution, our device could provide clean water to a family of 4 for ~10 years at the same price'
          ],
          images: [
            { src: aquatas_main, caption: '' },
            { src: aquatas_1, caption: 'Real device - built for pilot study in Sonora, Mexico' },
            { src: aquatas_2, caption: 'Internal view of quad pistons' },
            { src: aquatas_3, caption: 'Heavy metal solutions for testing' },
            { src: aquatas_4, caption: 'Really cool Nylon 12 3D printed pistons (lathed final ones)' },
            { src: aquatas_5, caption: 'DAQ system sensor setup' },
          ],
          links: [
            { text: 'Coverage by various sources', url: 'https://ventures.jhu.edu/programs-services/social-innovation-lab/sil-past-cohorts/sil-accelerator-cohort-teams-2019-20/aquatas/' },
          ],
        },
      },
    {
    id: 2,
    category: 'Hardware',
    image: benegraft_main,
    title: 'Hopkins Rapid Dicer',
    description: 'Being used across ORs in Korea 🏥',
    modalContent: {
      detailedTitle: 'Hopkins Rapid Dicer 🏥',
      tags: ['SOLIDWORKS', 'MATLAB', 'Machining', 'Rapid Prototyping', 'V&V', 'Injection Molding'],
      context: 'This started as an engineering project with me as the technical lead and then eventually became a company where I served as the CTO. The work was inspired / brought to our attention by <a href="https://my.clevelandclinic.org/about/overview/leadership/executive/byrne-patrick">Dr. Patrick Byrne</a> and completed under the guidance of <a href="https://www.bme.jhu.edu/people/faculty/nicholas-durr/">Dr. Nicholas Durr</a>, in partnership with the Johns Hopkins Department for Facial Plastics. <br /><br /> Our goal was to develop a novel surgical tool to aid with costal and septal cartilage dicing to construct moldable contour grafts during nasal reconstruction procedures.',
          technicalDetails: [
            '<strong>Some brief clinical context on the problem at hand</strong> - molding a diced cartilage graft with a glue mixture of fibrinogen and thrombin (TISSEEL, Baxter) delivers promising patient outcomes, but can take upwards of 1 hour and requires diced pieces around 1 mm in size to minimize external graft visibility and maximize chondrocyte activation for cartilage regeneration (TLDR; important procedure, but increases OR time and requires patient chest cavity to stay open longer)',
            'So the problem was pretty straightforward and we had tons of solutions in mind, but this project is really reflective of one key framework I was able to practice a lot - how could we navigate through the solution space and build the best product that would work reliably in a clinical setting (i.e., V&V)',
            'A concern I outlined was minimizing the shear strain on the cartilage during the cutting process to preserve cell viability' +
            '<ul>' + 
              '<li>I used FEA to develop a homogeneous and isotropic computation model for simulating cartilage biomechanics using ANSYS - this was used as a testing base to validate design iterations using local structural analysis</li>' +
              '</ul>',
            'I used a rapid prototyping approach to build thru dozens of different product iterations (also maintained a design history file along the way) - by the end we concluded a curved blade design would maximize contact force while cutting and result in reduced cartilage stress' +
              '<ul>' + 
              '<li>This led to a 9-bladed roller device which reduced cartilage processing time to under 5 minutes</li>' +
              '<li>Throughout the experiments, I created several tools including a constant pressure water sieve for size-based diced cartilage separation and a MATLAB texture analysis script to quantify graft surface roughness via GLCM</li>' +
              '<li>Noticing extensive cartilage loss to our device, I also designed an o-ring based columnar seal around the extruded blades for improved sealing</li>' +
              '</ul>',
          ],
          coolStuff: [
            'This project has a bit of everything - rapid prototyping, machining, documentation, V&V, leading a team of other engineers',
            '<strong>Successful exit</strong> - not only did we build something that worked, we patented it and were able to license it out…it is currently being used across hospitals in South Korea',
            'It was officially named after JHU (i.e., The Hopkins Rapid Dicer)'
          ],
          images: [
            { src: benegraft_main, caption: '' },
            { src: benegraft_1, caption: 'Final, production-ready device' },
            { src: benegraft_2, caption: 'Testing results' },
            { src: benegraft_3, caption: 'Product concepts along the way' },
          ],
          links: [
            { text: 'Production device demo', url: 'https://www.youtube.com/watch?v=Vjhj3fGHS5c' },
            { text: 'National engineering award', url: 'https://www.invent.org/blog/behind-nihf-scenes/2020-collegiate-inventors-competition-finalists' },
            { text: 'Publication', url: 'https://asmedigitalcollection.asme.org/medicaldevices/article-abstract/14/4/045002/1087896/A-Cartilage-Dicing-Tool-for-Rapid-Preparation-of?redirectedFrom=fulltext' },
            { text: 'Patent', url: 'https://patents.google.com/patent/US20230181209A1/en' },
          ],
        },
      },
  {
    id: 3,
    category: 'Hardware',
    image: stethoscope_main,
    title: 'Keychain Stethoscope',
    description: 'The accessory you were missing 🔑',
    modalContent: {
      detailedTitle: 'Keychain Digital Stethoscope 🔑',
      tags: ['Rapid Prototyping', 'C++', 'PCB Design', 'KiCad', 'LTSpice'],
      context: 'Long story short, I had a LilyPad (ATmega328) lying around in the BME design studio and wanted to do a quick weekend hack. So, I decided to build a mini digital stethoscope. The goal was to make this with a focus on circuit design and really getting good signal output.',
          technicalDetails: [
            'Overall this was a mix of both analog design + digital signal processing. The circuit + casing had the following components:' +
            '<ul>' + 
              '<li><strong>Physical “high-pass filter” via the casing design</strong> - although I didn’t go too crazy with modeling this computationally, I designed a 3D-printed casing with a flat-edge to block out noise not associated with heart sounds (this is sorta how real stethoscopes and helmholtz resonators work)</li>' +
              '<li><strong>Electret microphone</strong> - didn’t have any MEMS microphones, so used a standard electret microphone (honestly obliged to do so with Dr. James West’s office a few buildings down). The FET inside the microphone acts as a buffer at this stage, outputting a low-impedance audio signal</li>' +
              '<li><strong>Impedance matching + buffering</strong> - I added a high-input impedance op-amp buffer (TL071) to preserve the integrity of the signal without loading the microphone capsule</li>' +
              '<li><strong>Differentiation and amplification</strong> - I then added a passive differentiator network, followed by a precision non-inverting amplifier stage with selectable gain (i.e., a potentiometer on the LilyPad for gain changes on the fly based on the input signal amplitude)</li>' +
              '<li><strong>Filtering and envelope detection</strong> - to accommodate the crude input data capture, I added an additional stage of active filtering using a second-order Sallen-Key low-pass filter with a cutoff around 150 Hz to attenuate higher frequencies. This was followed by a simple envelope detector circuit to extract the rhythmic pulse of the heartbeat</li>' +
              '</ul>',
              'On the DSP side, the following steps were taken:' +
              '<ul>' + 
              '<li><strong>ADC</strong> - the LilyPad has a 10-bit ADC and I sampled data at ~1 KHz based on the Nyquist frequency</li>' +
              '<li><strong>FIR filter</strong> - there was still a bit of noise so I added an FIR filter to prioritize frequencies from heart beats (20 - 150 Hz)</li>' +
            '</ul>',
            'A few other things I did here:' +
            '<ul>' + 
              '<li>Used PWM to drive an LED as a visual indicator</li>' +
              '<li>Used an SPI communication protocol to visualize the signal on my phone</li>' +
            '</ul>',
            ],
          coolStuff: [
            'Turning a quick hack into a functional digital stethoscope over a weekend felt pretty rewarding',
            'I was also really happy with the signal output I achieved + the fact that I was able to bring in some DSP fundamentals also'
          ],
          images: [
            { src: stethoscope_1, caption: '' },
            { src: stethoscope_2, caption: '' },
            { src: stethoscope_3, caption: '' },
            { src: stethoscope_4, caption: 'Intermediate waveforms' },
            { src: stethoscope_5, caption: 'Look at the clear signal spikes!' },
          ],
          links: [
          ],
        },
      },
  {
    id: 4,
    category: 'Hardware',
    image: tractor_main,
    title: 'Tractor From Scratch',
    description: 'Built and modified a Ford 8N tractor 🚜',
    modalContent: {
      detailedTitle: 'Tractor From Scratch 🚜',
      tags: ['SOLIDWORKS', 'Drafting', 'GD&T', 'Machining'],
      context: 'This is probably one my favorite projects of all. It started as a short course on “reverse engineering” with other MechEs and then slowly expanded to something I was working on for ~1 year in between classes and finals. <br /><br /> The work involved breaking down a 1947 Ford 8N tractor to its individual pieces, learning how everything worked, and building it back up with a few modifications. The main catch: the only thing available to me in this entire process was the original 8N instruction manual (this should be the gold standard for engineering documentation btw)…no other support allowed. I also worked similarly on an old World War II Jeep.',
          technicalDetails: [
            'Parts of this were done as a group, some parts alone. A few major things I was able to directly contribute to included:' +
            '<ul>' + 
              '<li><strong>Complete engine rebuild (4-cylinder “L” head, ~24 hp)</strong> - this is an old tractor and hence there were some issues with power deficit and bearing knock. Upon dismantling the engine, I saw the pistons showed signs of wear, uneven wear patterns on both the main + connecting rod bearings, and there were a few broken seals also. So, I honed the cylinder walls, installed new oversized pistons with rings, and replaced all the gaskets + seals. Seeing the engine run again at the end of this was…insane</li>' +
              '<li><strong>Replace clutch disc + pressure plate</strong> - there was noticeable slipping for the tractor under the load, so my mind went to the transmission / clutch. Upon opening up the clutch assembly, I saw meaningful wear on its friction material and decided to replace it. Aligning the new clutch disc and pressure plate was a bit time-consuming / frustrating, but in the end it worked out</li>' +
              '<li>A few other things I did: standard maintenance on the radiator, brake shoe adjustment, minor wiring changes / replacing spark plugs in the ignition system</li>' +
              '</ul>',
            'Outside of this, I spent a lot of time just taking apart and learning how the starting motor worked (built out some CAD drawings of this also). This included using physics first principles to conduct some analysis also:' +
              '<ul>' + 
              '<li><strong>Gear calculations</strong> - flywheel has about 144 teeth, the starter drive pinion (sun gear) has about 9 teeth, the planetary gear has 18 teeth, and the ring gear has about 45 teeth…resulting in a total gear ratio of 96</li>' +
              '<li><strong>Torque output</strong> - it was my first time working with motors so I was curious. The engine has a 23.22 belt horsepower with a 27.32 maximum. Further, the engine low idle rpm is 400 and the high idle rpm is 2200. With an MA of 16 this resulted in a torque of ~25.8 N*m (best I could do without a torque vs rpm curve)</li>' +
              '<li><strong>Solenoid pull force</strong> -  the starter solenoid was really interesting. I used energy conservation and magnetic energy density to obtain the change in free energy - this resulted in a derived force equation (image attached). The approximate pull force was ~0.75 N.</li>' +
              '</ul>',
            ],
          coolStuff: [
            '<strong>First principles engineering</strong> - this was no BS, no crutch, fundamental engineering in the real world on an impressive piece of machinery. The satisfaction of building up the tractor from individual nuts and bolts and then driving it was second to none',
          ],
          images: [
            { src: tractor_1, caption: '' },
            { src: tractor_2, caption: '' },
            { src: tractor_3, caption: '' },
            { src: tractor_4, caption: 'Starting motor' },
            { src: tractor_5, caption: 'Pinion drive gear' },
            { src: tractor_6, caption: 'Starter solenoid' },
            { src: tractor_7, caption: '' },
          ],
          links: [
          ],
        },
      },
  {
    id: 5,
    category: 'Hardware',
    image: analog_main,
    title: 'Analog Spectrum Analyzer',
    description: 'I.e., I needed an LED DJ set for our house 🎧',
    modalContent: {
      detailedTitle: 'Analog Spectrum Analyzer 🎧',
      tags: ['PCB Design', 'KiCad', 'LTSpice'],
      context: 'This was a project for an electronics class final and I wanted to go fully analog. Coincidentally we had also moved into a cool new apartment off campus and were looking for decor. Hence I decided to build an analog spectrum analyzer that I could hook up to the sound system and drive and LED circuit. Part of the motivation was also to just try out a lot of different interesting designs.',
          technicalDetails: [
            'There were 4 main components to the circuit setup.',
            'First stage was to build in a set of precision active filters:' +
            '<ul>' + 
              '<li><strong>Low-pass</strong> - built a second-order Sallen-Key filter (used OPA2134 for its low noise) with a cutoff frequency of 200 Hz</li>' +
              '<li><strong>Band-pass</strong> - built a twin-T notch filter (1 KHz resonance, Q factor of 1.5), using a TL072 bc of its low harmonic distortion</li>' +
              '<li><strong>High-pass</strong> - implemented a butterworth filter here with a cutoff at 2 KHz</li>' +
              '<li>On top of this, I wanted to push things a little further by adding something to dynamically adjust these cutoff frequencies later (i.e., if I want to change things based on the genre of music being played). To do this, I used a combo of varactors and dual-gang potentiometers (RV16AF)</li>' +
              '</ul>',
            'Since I was connecting this to an LED driver circuit, I added a stage for gain amplification - this was via a non-inverting amplifier with a fixed gain of 51',
            'The third stage rectified the signal:' +
            '<ul>' + 
              '<li>Full wave rectification via a standard op-amp recitifier circuit (LM358)</li>' +
              '<li>Each rectifier output was fed into a simple envelope follower circuit to help smooth the signal (for a cool, gradual effect on LED intensity)</li>' +
              '</ul>',
            'Finally I built LED driver circuit' +
            '<ul>' + 
            '<li>To drive the LEDs, I used N-channel MOSFETs (allow handling current up to 1 A)</li>' +
            '<li>On top of this, I wanted to do some sort of an analog PWM to more finely control the LED intensity - to achieve this i added a basic peak detector circuit (using Schottky diodes) with a bleeder resistor to create a fading effect for the LEDs</li>' +
            '</ul>',
          ],
          coolStuff: [
            'Obviously a lot of what I built can be improved / replaced via DSP, but there was just a different kind of satisfaction / retro vibe doing this all in analog',
            '<strong>Dynamic adjustments</strong> - being able to tweak the filters for different music genres was super cool – almost like having a customizable light show'
          ],
          images: [
            { src: analog_2, caption: 'Low Pass' },
            { src: analog_3, caption: 'Band Pass' },
            { src: analog_4, caption: 'High Pass' },
          ],
          links: [
            { text: 'Video of working output', url: 'https://youtube.com/shorts/p4vngaTDwRE?feature=share' },
          ],
        },
      },
  {
    id: 6,
    category: 'Software / ML',
    image: lynq_main,
    title: 'Multimodal Agent with Compositional Reasoning',
    description: 'What if wearables had LTM and reasoning? 👀',
    modalContent: {
      detailedTitle: 'Multimodal Agent with Compositional Reasoning 👀',
      tags: ['Python', 'PostgreSQL', 'REST', 'Swift', 'Selenium', 'AWS', 'NLP'],
      context: 'Note: this started off as a fun technical project to dive deeper into the cognitive architectures surrounding agents. Eventually some of the work here was leveraged for my most recent venture in healthcare - Lynq. <br /><br /> I had played around with NLP earlier in college (and more recently building internal tools for my job at ClearView) when BERT first came out. And then again when reading about RNNs in Ian Goodfellow’s textbook. With GPT4 exploding, I became quite intrigued by agents…not the basic AutoGPT type, but more sophisticated cognitive agents with long term memory and compositional reasoning / tool use. With some prior work in wearable, I decided to dive deeper to build something which could be compatible with sensor data as well. The idea here is to build a personal agent which sits on top of your IoT data (amongst others) and can provide personalized insights across health and fitness.',
          technicalDetails: [
            'As a note, this was done with a Llama 2 13B model and deployed on the front end as a mobile application',
            'The overall agent framework was inspired by the <a href="https://arxiv.org/pdf/2304.03442.pdf">Stanford Generative Agents</a> and the <a href="https://arxiv.org/pdf/2304.09842.pdf">Microsoft Chameleon</a> papers (tons of others as well, I will add them below soon), modified slightly to be better suited for use with wearables in the health / fitness domain:' +
            '<ul>' + 
              '<li><strong>Curate</strong> - upon initialization (e.g., via user query), the first step is to automatically pull relevant data from a planning examples library, user memory, data schema, available tools, and separate knowledge base into a dynamic prompt. <a href="https://huggingface.co/BAAI/bge-large-en">FlagEmbedding</a> is used for this retrieval process</li>' +
              '<li><strong>Plan</strong> - the curated prompt is sent to the LLM with the goal of getting a structured json output which contains instructions (e.g., computing some metric, generating visualization, reflecting over results, etc.). Rather than fine-tuning the model just yet, I was relying on plain old ICL. As such, part of the task was to build a large enough example library (built via a hand-generated seed, then amplified by GPT4) and slot in the right one given the task at hand</li>' +
              '<li><strong>Act</strong> - no raw sensor / user data was sent to the LLM. Instead, I basically built a custom “compiler” which took the JSON output, ran a safety check (AST-based), pulled in the relevant data via an automated ETL process, and orchestrated the required tasks (i.e., via a function graph + pipeline executor). As a side note - I basically built out a massive, generalized function library specific to wearable health / fitness use cases which was called upon in this stage as needed</li>' +
              '<li><strong>Reflect</strong> - data has shown that role-based prompts do increase performance for these models. The final stage uses a “critic” approach to judge the plan + output in context with the user query + relevant metadata and decides one of two outcomes: 1) satisfactory (i.e., awesome, we can now send the response to the client) or 2) not satisfactory (i.e., append feedback and go back to the planning phase)</li>' +
              '</ul>',
            '<strong>Some other cool things as part of this included:</strong> making a dynamic visualization engine in swift for the front end, experimenting with a graph-based approach for memory curation / update, building in LLM-based active prompting over time (i.e., instead of just user initiating), and curating a knowledge base with some automated web scraping / cleaning',
            'In terms of eval, it is definitely a part of the project which needs more work. For now eval included of two things: 1) for basic questions (e.g., basic metrics to compute), this was just based on getting the right vs wrong answer, 2) for more qualitative or complex questions, using a custom rubric + seed examples to let GPT4 evaluate the plan. Truth be told, I am not the most optimistic about this approach, but this was partly inspired by the <a href="https://arxiv.org/pdf/2310.08491.pdf">Prometheus</a> paper and <a href="https://arxiv.org/pdf/2305.14387.pdf">AlpacaFarm</a>',
          ],
          coolStuff: [
            'I was really really happy with how this went. First of all, it was quite novel (at the time lol). Second, I actually used it to train for an Ironman 70.3 everyday. Third, it was a lot of work (20K+ LOC) and quite robust by the end',
            'A version of this was later tested in a beta with ~100 people and the 30-day retention was ~60%!!'
          ],
          images: [
            { src: lynq_1, caption: '' },
            { src: lynq_2, caption: 'System architecture' },
            { src: lynq_3, caption: '' },
          ],
          links: [
            { text: 'Brief demo', url: 'https://www.loom.com/share/2ed87460caab42f091e9d5c677e61198' },
          ],
        },
      },
  {
    id: 7,
    category: 'Software / ML',
    image: glioma_main,
    title: 'MRI Glioma Segmentation',
    description: 'Encoder-decoder based CNN to segment MRIs 🧠',
    modalContent: {
      detailedTitle: 'MRI Glioma Segmentation 🧠',
      tags: ['Computer Vision', 'MATLAB', 'Python', 'PyTorch'],
      context: 'This was a computer vision project under the guidance of <a href="https://engineering.jhu.edu/faculty/jerry-prince/">Dr. Jerry Prince</a>. Using a ~100 patient dataset of 4 MRI scans (T1, T2, T1-CE, FLAIR), the goal was to leverage a CNN-approach to segment gliomas. Specifically, the peritumoral edema (ED), necrotic and non-enhancing core (NCR/NET), and enhancing tumor (ET).',
          technicalDetails: [
            'My overall approach was to use an encoder-decoder based CNN framework with morphological processing for improved segmentation. This was partially based on the <a href="https://link.springer.com/chapter/10.1007/978-3-030-11726-9_28">work</a> by Andriy Myronenko at NVIDIA',
              '<strong>Preprocessing</strong> - the input data was already skull-stripped and registered to the same anatomical template. As such, only additional processing needed was Z-score normalization and image resizing (155x240x240 → 96x112x112)',
              'The model was trained on an NVIDIA Tesla K80 with the following architecture:' +
              '<ul>' +
                '<li><strong>Encoder</strong> - consists of ResNet blocks, each consisting of 2 strided convolutions, group normalization (batch size = 1), and ReLU</li>' +
              '<li><strong>Decoder</strong> - single ResNet blocks with 3D bilinear upsampling. This also used additive identity skip connections (i.e., encoder output of the equivalent spatial level is added at the end of each decoder block)</li>' +
              '<li><strong>Loss</strong> - soft dice loss applied at the decoder output</li>' +
              '<li><strong>Optimization</strong> - Adam optimizer (α_0 = 1𝑒 − 4)</li>' +
              '<li><strong>Regularization</strong> - L2 norm</li>' +
              '</ul>',
            '<strong>Morphological processing</strong> - the decoder output finally undergoes a morphological opening operation (erosion followed by dilation) to help group similar intensity pixels into clusters',
            'I used the Sorenson Dice Coefficient for eval - the mean score was 0.73, while the median was 0.77. The SoTA on the 2018 BRATS dataset is 0.87'
          ],
          coolStuff: [
            'It was a full circle moment since I deployed a CV approach to medical image analysis, after having spent the past year understanding how the medical imaging systems worked from a physics perspective',
            'Really great eval results, especially considering a relatively small dataset + my hunch for the morphological processing add-on worked out'          ],
          images: [
            { src: glioma_1, caption: '' },
            { src: glioma_2, caption: '' },
            { src: glioma_3, caption: '' },
          ],
          links: [
          ],
        },
      },
  {
    id: 8,
    category: 'Software / ML',
    image: sinogram_main,
    title: 'CT Image Reconstruction',
    description: 'A view into how medical imaging works 📷',
    modalContent: {
      detailedTitle: 'CT Image Reconstruction 📷',
      tags: ['MATLAB', 'Imaging'],
      context: 'I had just taken Medical Imaging Systems where we learned about the mathematical / physics background behind different imaging modalities. I wanted to actually apply some of this theory. Luckily, a friend was able to get me some raw sinogram data from his lab for me to play with (yeah I know, real cool…). <br /><br /> This project was a reconstruction of data (phantom with various colored dye) from a fan-beam tomographic system.',
          technicalDetails: [
            'The input here was a 2D .mat sinogram file which the projection data from the phantoms. Since this was collected with a single point source, the projection geometry was of the fan-bean configuration',
            'As such, the primary approach to reconstruct the image was via weighted back-projections as follows:' +
            '<ul>' + 
              '<li><strong>Determine voxel rays</strong> - unlike parallel beam projections, fan beam projections pass through the voxels at various angles, so we have to individually trace each of their paths (i.e., the U and s’ in the images)</li>' +
              '<li><strong>Map the pixel intensities</strong> - for each projection angle, we have to map the pixel intensities detected by each array detector back onto each voxel element (i.e., determine the distance, s’, each voxel is from the central detector and interpolate as needed)</li>' +
              '<li>Calculate the source-to-axis distance to avoid errors from diffracted rays (i.e., helps with weighting)</li>' +
              '<li><strong>Final weighted sum backprojection</strong> - finally we integrate the mapped intensities for each voxel across all projection angles to reconstruct the final image</li>' +
              '</ul>',
            ],
          coolStuff: [
          'Helped me gain a better appreciation of medical imaging + some self-respect as a BME lol'  
          ],
          images: [
            { src: ct_1, caption: 'Final reconstructed CT' },
            { src: ct_2, caption: '' },
            { src: ct_3, caption: 'Geometric overview of the ray tracing' },
            { src: ct_4, caption: 'Mapping the pixel intensities' },
          ],
          links: [
          ],
        },
      },
  {
    id: 9,
    category: 'Software / ML',
    image: deblur_main,
    title: 'Image Deblurring Algorithm',
    description: 'Implementing kernel estimation + residual RL deconvolution 🏙',
    modalContent: {
      detailedTitle: 'Image Deblurring Algorithm 🏙',
      tags: ['MATLAB', 'Imaging'],
      context: 'This was part of my final project for a class taught by <a href="https://www.bme.jhu.edu/people/faculty/j-webster-stayman/">Dr. J. Webster Stayman</a> focused on designing imaging systems and data processing methodologies. The goal of my project was to tackle the problem of blind motion deblurring which often occurs with phone cameras or with DSLRs at long exposure times.',
          technicalDetails: [
            '<strong>Some background on my technical approach</strong> - typically image blur arises from exposure, aperture, and ISO configurations. Long exposures induce motion blur, while short, high-gain exposures yield noise in dim light. Blur recovery techniques, like blind convolution, are unreliable for extensive blur with a single image so I used dual-image analysis (blurred + noisy pairs) for enhanced deblurring',
            '<strong>Overview of data collection</strong> - blurry images were obtained by setting our camera to a long exposure time (1 s) and an appropriate ISO for the lighting (400). Noisy images were obtained by having a short exposure time (0.25 s) and high ISO (3200) to lead to high gain. Some images from google were artificially blurred as well for standardization',
            'Everything here was implemented in MATLAB, with the below major components:' +
            '<ul>' + 
              '<li><strong>Initial denoising</strong> - I used pairs of blurred and noisy images of the same scene to derive an accurate blur kernel (critical for obtaining a sharper image with minimal artifacts and ringing)…simple median filtering was used for denoising</li>' +
              '<li><strong>Kernel estimation</strong> - an L1-regularized least squares method was used for this (because our solution may not be unique or be prone to overfitting). This essentially boils down to a least-squares / optimization problem with the following loss function: ||Ax - y||22+||x||1. From here, I implemented gradient descent, using the duality gap to improve the kernel estimate</li>' +
              '<li><strong>Regular deconvolution</strong> - once I had the kernel, I deconvolved to obtain the deblurred image</li>' +
              '<li><strong>Residual Richardson Lucy deconvolution</strong> - this is an iterative approach for solving systems of linear equations…I implemented it tackle ringing in the output images. the main thing to note here is the idea of the residual image, which is a lost detail layer from the denoised image. So here, the deconvolution was performed on the residual blurry image. This was obtained by the expression: resBlur = blurImg + resNoisy + 1</li>' +
              '</ul>',
            'Eval was done via frequency domain image blur measurements and GLCM metrics',
            'Overall this worked out well, but mostly for images with a uniform blur. Non-uniform blur is harder to tackle since since it can arise not only from multiple object motions but also from camera shake and scene depth variation. I began tackling this with a residual network, though that is not yet complete',
          ],
          coolStuff: [
          'Nowadays there are pre-built packages / libraries for this but it was a really satisfying process to implement a numerical solution from theory to practice from scratch…it certainly helped me approach problems more from a first principles perspective'  
          ],
          images: [
            { src: deblur_1, caption: '' },
            { src: deblur_2, caption: '' },
            { src: deblur_3, caption: '' },
          ],
          links: [
          ],
        },
      },
  {
    id: 10,
    category: 'Hardware + Software',
    image: hand_main,
    title: 'Real-Time Hand Pose Estimation',
    description: 'I do not like holding controllers with my Oculus 🕹',
    modalContent: {
      detailedTitle: 'Real-Time Hand Pose Estimation 🕹',
      tags: ['C++', 'MATLAB', 'Python', 'PyTorch', 'Computer Vision', 'SOLIDWORKS', 'PCB Design', 'KiCad', 'LTSpice'],
      context: 'This started off as an independent senior design project under Dr. Thakor. With COVID, I had to complete part of it outside of the JHU lab (luckily have my own mini lab at home). <br /><br /> In high school, I first saw the <a href=https://www.pranavmistry.com/archived/projects/sixthsense/>Sixth Sense device</a> by Pranav Mistry (legend) - he had open sourced it but I never got around to building it. Fast forward ~6 years, I was working on wearables / hemodynamic monitoring and wanted to extend that work into this type of a vibe. As part of BME coursework, we had worked on EMG-based prosthetic arm control, but I wanted to use an optics-based approach to capture rich signals from the carpal tunnel. <br /><br /> The goal was to leverage these signals for real-time hand pose estimation, something which would be a critical improvement for AR/VR as  existing headsets suffer from occlusion.',
          technicalDetails: [
            'Adding soon...lots of cool stuff to include here 😵‍💫'
          ],
          coolStuff: [
            '<strong>End-to-end</strong> - this project had a little bit of everything, ranging from mechanical design, to electronics, ML, and even data collection automation. Although collecting and curating the data was a bit of a pain, it was really really satisfying to see this produce something half-decent.',
          ],
          images: [
            { src: hand_1, caption: 'Example output from V1' },
            { src: hand_2, caption: 'Casing design' },
            { src: hand_3, caption: '' },
            { src: hand_4, caption: 'Sensor configuration' },
          ],
          links: [
          ],
        },
      },
  {
    id: 11,
    category: 'Hardware + Software',
    image: heart_main,
    title: 'LVAD Fluid Mechanics',
    description: 'We made an aritifical heart and looked inside it 🫀',
    modalContent: {
      detailedTitle: 'LVAD Fluid Mechanics 🫀',
      tags: ['LabView', 'DaVis', 'SOLIDWORKS', 'ANSYS Fluent', 'Machining', 'Experimental Design'],
      context: 'This is part of my work in the <a href="https://engineering.jhu.edu/lefd/">Katz Lab</a> at JHU, focused on experimental fluid dynamics. Whereas most of the projects in the lab were dealing with turbomachinery, I joined as part of the Heart Initiative - a JHU-wide effort to build the next-generation total artificial heart. I was the sole BME in a lab group of pure MechEs and so had the opportunity to take lead on much of the experimental setup + analysis. <br /><br /> My work was specifically centered around an experimental investigation into the flow inside of an LVAD. This employed advanced imaging techniques as well as some computational approaches to characterize the impact on blood coagulation (i.e., high shear flow and turbulent structures are bad for your heart).',
          technicalDetails: [
            'In terms of the basic setup, we had a 1:1 replica of a commercial VAD housed inside an acrylic block and driven by an external motor in a closed loop system',
            'I was responsible for most things end-to-end (i.e., it was just me and an extremely busy postdoc) - this included the experimental setup, conducting the experiments, and the subsequent data analysis. In particular, a few highlights included:' +
            '<ul>' + 
              '<li><strong>Setting up an automated data collection pipeline</strong> - 2D PIV requires a lot of properly collected data which I automated via LabView and an NI DAQ. In an effort to minimize error in the experimental design, I also implemented a PID control system to minimize any motor fluctuations</li>' +
              '<li><strong>Fluid preparation</strong> - our working fluid for the experiment consisted of glycerol, sodium iodide, and water to match the kinematic viscosity of human blood and the refractive index of the transparent acrylic blades. Since our lab did not have a viscometer, I created a falling ball viscometer using a CCD camera, a 0.794 mm diameter aluminum sphere, and a custom particle tracking MATLAB script to determine / prepare the proper fluid formula</li>' +
              '<li><strong>Data analysis</strong> - in terms of data collection, we inserted polyamide seed particles and captured 500 image pairs (based on convergence tests) for each of the 4 blade orientations. I processed the data and reconstructed velocity fields by tracking particles in the image pairs using DaVis</li>' +
              '</ul>',
            'The findings were really interesting - we found a complex flow structure including a tip leakage vortex near the rotor front end, extremely high turbulence levels, and secondary flows generated by blades dissecting previous vortices'
            ],
          coolStuff: [
            'This was a critical step for a very ambitious project (i.e., total artificial heart with an internal power source and no external wiring)',
            '<strong>Novel approach</strong> - this was the first time this degree of information was obtained experimentally and would have been difficult to model computationally',
            '<strong>End-to-end implementation of a robust experimental setup</strong> - I got the opportunity to play a significant role in all parts of the process (and an excuse to play with some very expensive toys)'
          ],
          images: [
            { src: heart_1, caption: '' },
            { src: heart_2, caption: 'Rotor made in house (look at the threading!)' },
            { src: heart_3, caption: 'External motor' },
            { src: heart_4, caption: 'Housing with fluid' },
            { src: heart_5, caption: 'Testing setup' },
            { src: heart_6, caption: 'Super random, but really cool resin pieces' },
            { src: heart_7, caption: 'Example analysis output' },
          ],
          links: [
            { text: 'Paper', url: 'https://asmedigitalcollection.asme.org/FEDSM/proceedings-abstract/AJKFluids2019/59056/V03BT03A050/1069218' },
            { text: 'Additional coverage', url: 'https://hub.jhu.edu/gazette/2015/may-june/focus-can-we-build-a-better-heart/' },
          ],
        },
      },
  {
    id: 12,
    category: 'Hardware + Software',
    image: probe_main,
    title: 'Optical Surgical Probe',
    description: 'Enabling palpation haptics during robotic surgery 🦾',
    modalContent: {
      detailedTitle: 'Optical Surgical Probe 🦾',
      tags: ['C++','ANSYS Speos','SOLIDWORKS','PCB Design','KiCad','Rapid Prototyping'],
      context: 'This was part of an independent senior research project, but was cut short due to the COVID pandemic. <br /><br /> The main idea was to design a novel palpation probe for the Da Vinci Surgical Machine. Palpation is a crucial tool used by surgeons for critical medical decision making. With the rise of robotic systems and minimally invasive procedures, the tactile feedback is becoming more limited so the goal was to develop a small, high precision probe which could assess tissue stiffness and provide feedback to the surgeons via a haptics module.',
          technicalDetails: [
            'At its core, the probe uses optical principles to measure tissue indentation. The primary mechanism involves a spherical indenter with an edge-spanning slit, enabling the estimation of indentation depth based on changes in light transmission',
            'Stiffness is load over displacement, so the key challenge is to determine tissue deflection / displacement. This is done with the following approach:' +
            '<ul>' + 
              '<li><strong>LED + photoresistor mechanism</strong> - the indenter houses an LED and a photoresistor (GL5528). As the indenter is pressed against tissue, the slit coverage changes, altering the light reaching the photoresistor</li>' +
              "<li><strong>Converting voltage to indentation depth</strong> - the photoresistor's varying resistance due to light exposure alters the voltage across it. We can then calibrate the voltage readings with known indentation depths and subsequently proxy displacement via voltage</li>" +
              '<li>The probe can viewed as a 2D sensor array which also measures force applied during indentation (via mini barometers MPL115A1, and a dragonskin 10 exterior), resulting in a discrete 2D stiffness map</li>' +
              '<li><strong>B-spline interpolation</strong> - we can then interpolate across this 2D grid to get a more continuous map of tissue stiffness across the probe</li>' +
              '</ul>',
            'As a side note, I also interviewed ~30 surgeons around the country (and at JHU) to gain user validation on my approach',
          ],
          coolStuff: [
            '<strong>Novel optics approach</strong> - a lot of the training ingrained in us as BMEs was to use first principles thinking around physics + math to design innovative technology. I was pleased with applying that here and it led to the development of a novel sensor',
            '<strong>Realistic application</strong> - haptics is critical to robotic surgery and will only grow in importance in the coming years. As such the overall design of the probe + stiffness mapping was very in tune with existing literature and surgeon preferences'
          ],
          images: [
            { src: probe_1, caption: 'Probe design' },
            { src: probe_2, caption: 'Individual, 2-layer optics module' },
            { src: probe_3, caption: 'Derivation of indentation depth' },
            { src: probe_4, caption: '' },
          ],
          links: [
          ],
        },
      },
  {
    id: 13,
    category: 'Hardware + Software',
    image: swarm_main,
    title: 'Mini Swarm Robotics',
    description: 'Very 2010s, but trynna make my inner child happy 🤖',
    modalContent: {
      detailedTitle: 'Mini Swarm Robotics 🤖',
      tags: ['C++', 'Python', 'PyTorch', 'PCB Design', 'KiCad', 'LTSpice', 'SOLIDWORKS', 'Rapid Prototyping'],
      context: 'I have always been fascinated by robotics - it is part of the reason I got into haptics work with the DaVinci at JHU. In particular, <a href="https://www.csail.mit.edu/person/daniela-rus">Dr. Daniela Rus</a> at CSAIL has been an inspiration for me from afar. I remember reading the <a href="https://ieeexplore.ieee.org/document/6696971">M-blocks paper</a> in high school (and then also saw Big Hero 6) and have thus had this project on the list for a while. <br /><br /> My take on it is a bit different - structurally I am using the geometry of a truncated icosahedron (sorta like the <a href="https://asmedigitalcollection.asme.org/IDETC-CIE/proceedings-abstract/IDETC-CIE2014/46377/V05BT08A029/257698">Buckybot paper</a> from JHU). It is fitted with internal actuators for movement, sonar-based range finders, small RFID tags, and electromagnets on select faces for controllable attachment and detachment.',
          technicalDetails: [
          'Coming soon...we are cooking 🧑‍🍳'  
          ],
          coolStuff: [
          'Coming soon'  
          ],
          images: [
            { src: temp, caption: '' },
          ],
          links: [
          ],
        },
      }
];

export default projectsData;
