import './App.css';
import Intro from './components/Intro/Intro';
import Timeline from './components/Timeline/Timeline';
import Projects from './components/Projects/Projects';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <div className="App">
      <Intro />
      <hr className="section-divider" />
      <Projects />
      <Analytics />
      <SpeedInsights />
    </div>
  );  
}

export default App;
