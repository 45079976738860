import React from 'react';
import './Timeline.css';
import jhu from './jhu.jpeg';
import lynq from './lynq.png';
import clearview from './clearview.jpeg';
import startup from './startup.png';

function TimelineEntry({ year, title, company, description, logo }) {
    return (
      <div className="timeline-entry">
        <span className="timeline-year">{year}</span>
        <img src={logo} alt={company} className="timeline-logo" />
        <div className="timeline-text">
          <h3 className="timeline-title">{title}</h3>
          <p className="timeline-company">{company}</p>
          <p className="timeline-description">{description}</p>
        </div>
      </div>
    );
  }
  
  function Timeline() {
    return (
      <div className="timeline">
        <h2 className="timeline-header">Some Places I've Worked At</h2>
        <TimelineEntry
          year="2023 - 2024"
          title="TBD"
          company="TBD"
          description="TBD"
          logo={lynq}
        />
        <TimelineEntry
          year="2020 - 2023"
          title="TBD"
          company="TBD"
          description="TBD"
          logo={clearview}
        />
        <TimelineEntry
          year="2017 - 2020"
          title="TBD"
          company="TBD"
          logo={startup}
        />
        <TimelineEntry
          year="2016 - 2020"
          description="BSc at Johns Hopkins University in Biomedical Engineering, with a focus on sensors, instrumentation, and imaging."
          logo={jhu}
        />
      </div>
    );
  }

export default Timeline;
